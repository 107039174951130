import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useSubmit } from "react-router-dom";
import { useState } from "react";
import { redirect, useLoaderData } from "react-router-dom";

import {
  getProjects,
  Project,
  syncProjects,
  updateProject,
} from "../api/backend/projects";
import { WithPagination } from "../api/backend/types";
import { AddProject } from "./modals/add-project";
import { RemoveProject } from "./modals/remove-project";
import { Pagination } from "./pagination";
import { Modal } from "./modals/common";
import { Loader } from "./Loader";

export const Projects = () => {
  const submit = useSubmit();
  const projectsData = useLoaderData() as WithPagination<Project[]>;

  const [data, setData] = useState(projectsData);

  const [isAddProjectOpen, setIsAddProjectOpen] = useState(false);
  const [isRemoveProjectOpen, setIsRemoveProjectOpen] = useState(false);

  const [projectToRemove, setProjectToRemove] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);

  const onRemoveButtonClick = (id: number) => {
    setProjectToRemove(id);
    setIsRemoveProjectOpen(true);
  };

  const paginationInfo = {
    currentPage: Math.ceil(data.offset / data.limit), // 0...inf
    perPage: data.limit, // 1...inf
    total: data.totalNumber, // 0...inf
  };

  const onPagination = async (currentPage?: number) => {
    onButtonClick(currentPage);
  };

  const onButtonClick = async (currentPage?: number) => {
    const newData = await loader({
      currentPage,
    });

    setData(newData);
  };

  const handleClearLogo = async (id: number) => {
    const result = await updateProject({
      isItNecessaryToRemoveTheLogo: true,
      projectId: id,
    });
    console.log("image cleared", result);

    submit(null, { method: "put", action: "/projects" });
  };

  const handleFileInputChange = async (event: any, id: number) => {
    const file = event.target.files[0];

    // Check MIME type
    const acceptedTypes = ["image/jpeg", "image/png", "image/svg+xml"];
    if (acceptedTypes.indexOf(file.type) === -1) {
      alert("Only JPEG, PNG, and SVG files are accepted");
      event.target.value = null; // clear the file input
      return;
    }

    const result = await updateProject({
      logo: file,
      projectId: id,
    });
    console.log("image updated", result);

    submit(null, { method: "put", action: "/projects" });
  };

  const syncProjectsWithIndexer = async () => {
    setLoading(true);
    setOpenSyncModal(true);
    await syncProjects();
    setLoading(false);
    submit(null, { method: "put", action: "/projects" });
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <span className="text-xl font-semibold text-gray-900">Projects</span>
          <div className="flex space-x-4">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setIsAddProjectOpen(true)}
            >
              Add project
            </button>
            <button
              type="button"
              title="update the list of external projects if it does not match local projects"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={syncProjectsWithIndexer}
            >
              Sync projects with indexer{" "}
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2  overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Logo
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Account Address
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {projectsData.data.map(
                      ({ name, walletAddress, logo, id }) => (
                        <tr key={walletAddress}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            <div className="h-10 w-10 rounded-lg border border-gray-300 hover:border-gray-500 hover:cursor-pointer border-dotted relative">
                              {logo && (
                                <div
                                  className="rounded-full bg-gray-100 hover:bg-gray-200 hover:text-gray-700 p-1 absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-gray-400 "
                                  onClick={() => handleClearLogo(id!)}
                                >
                                  <XMarkIcon className="h-3 w-3 " />
                                </div>
                              )}
                              {logo && (
                                <div className="h-full w-full flex justify-center items-center overflow-hidden rounded-lg">
                                  <img
                                    src={logo}
                                    className="object-fill h-full w-full"
                                    alt="logo"
                                  />
                                </div>
                              )}
                              <input
                                className="absolute left-0 text-[0] m-0 p-0 top-0 opacity-0 file:cursor-pointer block h-full w-full hover:cursor-pointer"
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleFileInputChange(e, id!)}
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {name || "no name"}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            style={{
                              wordBreak: "break-all",
                              fontFamily: "monospace, monospace",
                            }}
                          >
                            {walletAddress}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2 sm:w-auto"
                              onClick={() => onRemoveButtonClick(id!)}
                            >
                              Remove project
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {paginationInfo.total > paginationInfo.perPage && (
          <Pagination
            currentPage={paginationInfo.currentPage}
            perPage={paginationInfo.perPage}
            total={paginationInfo.total}
            onClick={onPagination}
          />
        )}
      </div>

      {isAddProjectOpen && !isRemoveProjectOpen && (
        <AddProject onClose={() => setIsAddProjectOpen(false)} />
      )}
      {!isAddProjectOpen && isRemoveProjectOpen && projectToRemove && (
        <RemoveProject
          onClose={() => {
            setIsRemoveProjectOpen(false);
            setProjectToRemove(undefined);
          }}
          id={projectToRemove}
        />
      )}
      {openSyncModal && (
        <Modal onClose={() => setOpenSyncModal(false)}>
          <div className="flex flex-col items-center justify-center">
            {loading ? (
              <div>
                <Loader />
                <span className="mt-4">Synchronizing...</span>
              </div>
            ) : (
              <div className="flex items-center flex-col">
                <CheckIcon className="text-green-400 h-6 w-6" />

                <div className="mt-4">All done!</div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

Projects.action = async () => {
  return redirect(`/projects`);
};

const loader = async ({
  currentPage = 0,
}: Partial<{
  currentPage?: number;
}>) => {
  const projectsData = await getProjects({
    offset: currentPage.toString(),
  });

  console.log("projects", projectsData);

  if (!projectsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  return projectsData;
};

Projects.loader = async () => {
  return loader({});
};
