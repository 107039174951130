import { useState } from "react";
import { Link, redirect, useLoaderData } from "react-router-dom";

import { Complaint, Event, getComplaints, getEvents } from "../api/backend";
import { EventStatus, WithPagination } from "../api/backend/types";
import { getProjects, Project } from "../api/indexer";
import { getStatusClass } from "../utils/event";
import { cutAddress } from "../utils/helpers";
import { UpdateEvent } from "./modals/update-event";
import { Pagination } from "./pagination";
import { Item, Select } from "./select";

const hideChangeButtonStatuses: EventStatus[] = ["past"];

const emptyItem: Item = {
  id: `__empty`,
  label: `Not selected`,
};

export const Events = () => {
  const loadedData = useLoaderData() as {
    eventsData: WithPagination<Event[]>;
    projectsData: Project[];
    complaintsData?: Complaint[];
  };

  console.log("complaintsData", loadedData.complaintsData);

  const [data, setData] = useState(loadedData);

  const [isChangeEventOpen, setIsChangeEventOpen] = useState(false);

  const [eventToChange, setEventToChange] = useState<Event | undefined>();

  const onChangeButtonClick = (changeEventData: Event) => {
    setEventToChange(changeEventData);
    setIsChangeEventOpen(true);
  };

  const paginationInfo = {
    currentPage: Math.ceil(data.eventsData.offset / data.eventsData.limit), // 0...inf
    perPage: data.eventsData.limit, // 1...inf
    total: data.eventsData.totalNumber, // 0...inf
  };

  const onPagination = async (currentPage?: number) => {
    onButtonClick(currentPage);
  };

  const [selectedProjectName, setSelectedProjectName] = useState<
    Item | undefined
  >(emptyItem);
  const [selectedStatus, setSelectedStatus] = useState<Item | undefined>(
    emptyItem
  );

  const onButtonClick = async (currentPage?: number) => {
    const newData = await loader({
      projectName:
        selectedProjectName?.id === emptyItem.id
          ? undefined
          : selectedProjectName?.label,
      eventStatus:
        selectedStatus?.id === emptyItem.id ? undefined : selectedStatus?.label,
      currentPage,
    });

    setData(newData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <span className="text-xl font-semibold text-gray-900">Events</span>
        </div>

        <br />

        <>
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                <>Get events</>
              </h3>
            </div>

            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-4">
                <Select
                  title="By project name"
                  items={[emptyItem].concat(
                    data.projectsData.map(({ name, accountAddr }) => ({
                      id: `${name}/${accountAddr}`,
                      label: name || "No name",
                    }))
                  )}
                  selectedItem={selectedProjectName}
                  setSelectedItem={setSelectedProjectName}
                />
              </div>

              <div className="col-span-4">
                <Select
                  title="By status"
                  items={[emptyItem].concat(
                    (
                      [
                        "active",
                        "moderation",
                        "rejected",
                        "past",
                      ] as EventStatus[]
                    ).map((status) => ({
                      id: status,
                      label: status,
                    }))
                  )}
                  selectedItem={selectedStatus}
                  setSelectedItem={setSelectedStatus}
                />
              </div>

              <div className="col-span-4">
                &nbsp;
                <div className="text-sm font-medium text-gray-700 flex content-end">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={() => onButtonClick()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>

        <div className="mt-8 flex flex-col">
          <div className="-my-2  overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Logo
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Complaints
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.eventsData.data.map((eventData) => {
                      const { id, companyName, companyAddress, status, logo } =
                        eventData;
                      // count complaints with eventid === id
                      const complaintsCount = data?.complaintsData?.filter(
                        (complaint) => complaint.eventId === id
                      ).length;

                      return (
                        <tr key={id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-600 sm:pl-6 lg:pl-8 hover:underline">
                            <Link to={`/events/${id}`}>{id}</Link>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-600 sm:pl-6 lg:pl-8 hover:underline">
                            {logo && (
                              <Link to={`/events/${id}`}>
                                <img
                                  src={logo}
                                  alt="logo"
                                  className="max-h-8 max-w-8"
                                />
                              </Link>
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-600 sm:pl-6 lg:pl-8 hover:underline">
                            <Link to={`/events/${id}`}>
                              {companyName || "no name"}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {cutAddress(companyAddress)}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            style={{
                              wordBreak: "break-all",
                              fontFamily: "monospace, monospace",
                            }}
                          >
                            <span
                              className={getStatusClass({
                                status,
                                additionalClasses:
                                  "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                              })}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {status}
                            </span>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-600 sm:pl-6 lg:pl-8 hover:underline">
                            {!!complaintsCount && (
                              <Link to={`/events/${id}#complaints`}>
                                {complaintsCount}
                              </Link>
                            )}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            {!hideChangeButtonStatuses.includes(status) ? (
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                  onChangeButtonClick(eventData);
                                }}
                                style={{
                                  opacity: "80%",
                                }}
                              >
                                Change status
                              </button>
                            ) : (
                              <>&nbsp;</>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        {paginationInfo.total > paginationInfo.perPage && (
          <Pagination
            currentPage={paginationInfo.currentPage}
            perPage={paginationInfo.perPage}
            total={paginationInfo.total}
            onClick={onPagination}
          />
        )}
      </div>

      {isChangeEventOpen && eventToChange && (
        <UpdateEvent
          name={eventToChange.companyName}
          image={
            process.env.REACT_APP_DEV_MODE === "true"
              ? "https://scientificrussia.ru/images/b/teb-full.jpg"
              : eventToChange.logo
          }
          description={eventToChange.description}
          fields={[
            { key: "id", value: eventToChange.id.toString() },
            {
              key: "companyAddress",
              value: eventToChange.companyAddress,
              type: "address",
            },
            { key: "status", value: eventToChange.status, type: "status" },
            // todo tags
            { key: "twitter", value: eventToChange.twitter, type: "url" },
            { key: "createdAt", value: eventToChange.createdAt, type: "date" },
            { key: "updatedAt", value: eventToChange.updatedAt, type: "date" },
            { key: "startDate", value: eventToChange.startDate, type: "date" },
            { key: "endDate", value: eventToChange.endDate, type: "date" },
          ]}
          onClose={() => {
            setIsChangeEventOpen(false);
            setEventToChange(undefined);

            onPagination();
          }}
          eventToChange={eventToChange}
        />
      )}
    </>
  );
};

Events.action = async () => {
  return redirect(`/events`);
};

const loader = async ({
  projectName,
  eventStatus,
  currentPage = 0,
}: Partial<{
  projectName: string;
  eventStatus: string;
  currentPage?: number;
}>) => {
  const eventsData = await getEvents({
    companyName: projectName,
    statuses: eventStatus,
    offset: currentPage.toString(),
  });

  const projectsData = await getProjects();

  let complaintsData = await getComplaints({
    offset: "0",
    limit: "100",
  });

  if (complaintsData?.totalNumber! < 100) {
    complaintsData = await getComplaints({
      offset: "0",
      limit: complaintsData?.totalNumber.toString(),
    });
  }

  if (!eventsData || !projectsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  return { eventsData, projectsData, complaintsData: complaintsData?.data };
};

Events.loader = async () => {
  return loader({});
};
