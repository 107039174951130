import { useState } from "react";
import { redirect, useLoaderData } from "react-router-dom";

import { EventTag, getEventTags } from "../api/backend";
import { WithPagination } from "../api/backend/types";
import { UpdateEventTag } from "./modals/update-event-tag";
import { Pagination } from "./pagination";

export const EventTags = () => {
  const loadedData = useLoaderData() as {
    eventTagsData: WithPagination<EventTag[]>;
  };

  const [data, setData] = useState(loadedData);

  const [isAddEventTagOpen, setIsAddEventTagOpen] = useState(false);
  const [isChangeEventTagOpen, setIsChangeEventTagOpen] = useState(false);

  const [eventTagToChange, setEventTagToChange] = useState<
    EventTag | undefined
  >();

  const onChangeButtonClick = (changeEventTagData: EventTag) => {
    setEventTagToChange(changeEventTagData);
    setIsChangeEventTagOpen(true);
  };

  const paginationInfo = {
    currentPage: Math.ceil(
      data.eventTagsData.offset / data.eventTagsData.limit
    ), // 0...inf
    perPage: data.eventTagsData.limit, // 1...inf
    total: data.eventTagsData.totalNumber, // 0...inf
  };

  const onPagination = async (currentPage?: number) => {
    const newData = await loader({
      currentPage,
    });

    setData(newData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <span className="text-xl font-semibold text-gray-900">EventTags</span>
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setIsAddEventTagOpen(true)}
          >
            Add eventTag
          </button>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2  overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.eventTagsData.data.map(
                      ({ name, id, status, createdAt, updatedAt }) => (
                        <tr key={id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {name || "no name"}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            style={{
                              wordBreak: "break-all",
                              fontFamily: "monospace, monospace",
                            }}
                          >
                            <span
                              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                                status === "enable"
                                  ? "text-green-800 bg-green-100"
                                  : "text-red-800 bg-red-100"
                              }`}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {status}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                              onClick={() =>
                                onChangeButtonClick({
                                  id,
                                  name,
                                  status,
                                  createdAt,
                                  updatedAt,
                                })
                              }
                              style={{
                                opacity: "80%",
                              }}
                            >
                              Change status
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <br />
                {paginationInfo.total >= paginationInfo.perPage && (
                  <Pagination
                    currentPage={paginationInfo.currentPage}
                    perPage={paginationInfo.perPage}
                    total={paginationInfo.total}
                    onClick={onPagination}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isChangeEventTagOpen && isAddEventTagOpen && (
        <UpdateEventTag
          onClose={() => {
            setIsAddEventTagOpen(false);

            onPagination();
          }}
        />
      )}
      {isChangeEventTagOpen && !isAddEventTagOpen && eventTagToChange && (
        <UpdateEventTag
          onClose={() => {
            setIsChangeEventTagOpen(false);
            setEventTagToChange(undefined);

            onPagination();
          }}
          eventTagToChange={eventTagToChange}
        />
      )}
    </>
  );
};

EventTags.action = async () => {
  return redirect(`/event-tags`);
};

EventTags.loader = async () => {
  const eventTagsData = await getEventTags();

  if (!eventTagsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  return { eventTagsData };
};

const loader = async ({
  currentPage = 0,
}: Partial<{
  currentPage?: number;
}>) => {
  const eventTagsData = await getEventTags({
    offset: currentPage.toString(),
  });

  if (!eventTagsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  return { eventTagsData };
};

EventTags.loader = async () => {
  return loader({});
};
