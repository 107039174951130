import { useState } from "react";
import { useSubmit } from "react-router-dom";

import { Event, updateEvent } from "../../api/backend";
import { EventStatus } from "../../api/backend/types";
import { FieldType, parseFieldValue } from "../../utils/event";
import { Modal } from "./common";

export const eventStatuses: {
  id: EventStatus;
  title: string;
}[] = [
  { id: "active", title: "Active" },
  { id: "moderation", title: "Moderation" },
  { id: "rejected", title: "Rejected" },
  { id: "past", title: "Past" },
];

export const disablesStatusIds = ["past"];

type Field = {
  key: string;
  value: string;
  type?: FieldType;
};
export const UpdateEvent = ({
  name,
  image,
  description,
  fields,
  onClose,
  eventToChange,
}: {
  name: string;
  image: string;
  description: string;
  fields: Field[];
  onClose: () => void;
  eventToChange: Event;
}) => {
  const submit = useSubmit();

  const [status, setStatus] = useState(eventToChange.status);

  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const clearState = () => {
    setIsActionInProgress(false);
  };

  const onStatusChange = (e: any) => {
    const id = e.target.value as string | undefined;
    if (!id) return;

    setStatus(eventStatuses.find((eventStatus) => eventStatus.id === id)?.id!);
  };

  const onButtonClick = async () => {
    setIsActionInProgress(true);

    await updateEvent({
      eventId: eventToChange.id,
      status,
    });

    submit(null, { method: "post", action: "/events" });

    clearState();

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {name}
          </h3>
        </div>

        <br />

        <div className="space-y-6 pb-6">
          <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
            {!!image && <img src={image} alt="" className="object-cover" />}
          </div>

          <div>
            <h3 className="font-medium text-gray-900">Description</h3>
            <div className="mt-2 flex items-center justify-between">
              <p className="text-sm italic text-gray-500">{description}</p>
            </div>
          </div>

          <div>
            <h3 className="font-medium text-gray-900">Information</h3>
            <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
              {fields.map(({ key, value, type }) => (
                <div
                  key={key}
                  className="flex justify-between py-3 text-sm font-medium"
                >
                  <dt className="text-gray-800">{key}</dt>
                  <dd className="whitespace-nowrap text-gray-900">
                    {parseFieldValue({
                      field: value,
                      fieldType: type,
                    })}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div>
            <h3 className="font-medium text-gray-900">Status</h3>
            <fieldset className="mt-4">
              <div
                className="mt-2 flex justify-between"
                onChange={onStatusChange}
              >
                {eventStatuses.map(({ id, title }) => (
                  <div key={id} className="flex items-center">
                    <input
                      disabled={disablesStatusIds.includes(id)}
                      defaultChecked={status === id}
                      value={id}
                      id={id}
                      name="event"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      style={
                        disablesStatusIds.includes(id)
                          ? {
                              cursor: "default",
                            }
                          : {
                              cursor: "pointer",
                            }
                      }
                    />
                    <label
                      htmlFor={id}
                      className="ml-3 block text-sm font-medium text-gray-700"
                      style={
                        disablesStatusIds.includes(id)
                          ? {
                              cursor: "default",
                            }
                          : {
                              cursor: "pointer",
                            }
                      }
                    >
                      {title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            onClick={onButtonClick}
            disabled={isActionInProgress || eventToChange.status === status}
          >
            Update status
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={onClose}
            disabled={isActionInProgress}
          >
            Cancel
          </button>
        </div>
      </>
    </Modal>
  );
};
