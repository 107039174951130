import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import { getProjectsApplications, ProjectApplication } from "../api/backend";
import { WithPagination } from "../api/backend/types";
import { parseFieldValue } from "../utils/event";
import { Pagination } from "./pagination";

export const ProjectsApplications = () => {
  const loadedData = useLoaderData() as {
    projectsApplicationsData: WithPagination<ProjectApplication[]>;
  };

  const [data, setData] = useState(loadedData);

  const paginationInfo = {
    currentPage: Math.ceil(
      data.projectsApplicationsData.offset / data.projectsApplicationsData.limit
    ), // 0...inf
    perPage: data.projectsApplicationsData.limit, // 1...inf
    total: data.projectsApplicationsData.totalNumber, // 0...inf
  };

  const onPagination = async (currentPage: number) => {
    const newData = await loader({
      currentPage,
    });

    setData(newData);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <span className="text-xl font-semibold text-gray-900">
            Projects applications
          </span>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2  overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        CreatedAt
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Project name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        E-mail
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Website link
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Telegram account
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.projectsApplicationsData.data.map(
                      ({
                        id,
                        projectName,
                        email,
                        linkWebsite,
                        telegramAccount,
                        createdAt,
                      }) => (
                        <tr key={id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {parseFieldValue({
                              field: createdAt,
                              fieldType: "date",
                            })}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {projectName || "no name"}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {parseFieldValue({
                              field: email,
                              fieldType: "email",
                              url: email,
                            }) || "no name"}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {parseFieldValue({
                              field: linkWebsite,
                              fieldType: "url",
                              url: linkWebsite,
                            }) || "no name"}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {parseFieldValue({
                              field: telegramAccount,
                              fieldType: "url",
                              url:
                                "https://t.me/" +
                                telegramAccount.trim().replace(/^@/, ""),
                            }) || "no name"}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <br />
                {paginationInfo.total >= paginationInfo.perPage && (
                  <Pagination
                    currentPage={paginationInfo.currentPage}
                    perPage={paginationInfo.perPage}
                    total={paginationInfo.total}
                    onClick={onPagination}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const loader = async ({
  currentPage = 0,
}: Partial<{
  currentPage?: number;
}>) => {
  const projectsApplicationsData = await getProjectsApplications({
    offset: currentPage.toString(),
    limit: "10",
  });

  if (!projectsApplicationsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  return { projectsApplicationsData };
};

ProjectsApplications.loader = async () => {
  return loader({});
};
