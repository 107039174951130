import { BASE_API_URL } from "../../constants";
import { transformToOnlyStringObject } from "../../utils/helpers";
import { Sorting, WithPagination } from "./types";

// const getFakeData: (offset?: number) => WithPagination<ProjectApplication[]> = (
//   offset
// ) => {
//   const totalNumber = 5;
//
//   return {
//     data: new Array(totalNumber).fill("").map((_, i) => {
//       const n = i + 1;
//
//       return {
//         projectName: `Fake project ${n}`,
//         linkWebsite: `https://fake.fake/${n}`,
//         email: `fake-${n}@fake.com`,
//         telegramAccount: `@fake${n}`,
//
//         id: n,
//       };
//     }),
//     offset: offset || 0,
//     limit: 10,
//     totalNumber,
//   };
// };

type ProjectsApplicationsFilter = Partial<{
  // todo

  sorting: Sorting;
  offset: string; // number;
  limit: string; // number;
}>;

export type ProjectApplication = {
  // todo
  createdAt: string;
  updatedAt: string;
  projectName: string;
  linkWebsite: string;
  email: string;
  telegramAccount: string;
  status: string;
  id: number;
};

export const getProjectsApplications = async (
  params?: ProjectsApplicationsFilter
) => {
  // if (!!"todo") return getFakeData(params?.limit ? +params.limit : undefined);
  // /api/project-applications/filter
  const apiUrl = `${BASE_API_URL}/api/project-applications/filter`;

  try {
    const _params: ProjectsApplicationsFilter = {
      sorting: "createdAt_desc",
      ...params,
      //
    };
    const response = await fetch(
      `${apiUrl}?${new URLSearchParams(transformToOnlyStringObject(_params))}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          accept: "application/json",
        },
      }
    );
    if (response?.ok) {
      const json: WithPagination<ProjectApplication[]> = await response.json();

      console.log(`[project-applications] Result::`, json);

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};
