import { useEffect } from "react";
import { useSubmit } from "react-router-dom";
import { checkAuth } from "../../api/backend";

export const useAuthCheck = () => {
  const submit = useSubmit();

  useEffect(() => {
    (function ping() {
      checkAuth().then((isAuth) => {
        if (isAuth) {
          setTimeout(ping, 60 * 1000);
        } else {
          submit(null, { method: "post", action: "/login" });
        }
      });
    })();
  }, [submit]);

  return submit;
};
