import { Switch } from "@headlessui/react";
import { useState } from "react";
import { useSubmit } from "react-router-dom";

import { EventTag, updateEventTag } from "../../api/backend";
import { EventTagStatus } from "../../api/backend/types";
import { classNames, rmSpaces } from "../../utils/helpers";
import { Modal } from "./common";

const getStatus: (isEnabled: boolean) => EventTagStatus = (isEnabled) =>
  isEnabled ? "enable" : "disable";

export const UpdateEventTag = ({
  onClose,
  eventTagToChange,
}: {
  onClose: () => void;
  eventTagToChange?: EventTag;
}) => {
  const submit = useSubmit();

  const [name, setName] = useState(
    eventTagToChange?.id ? eventTagToChange.name : ""
  );
  const [showNameRequiredWarning, setShowNameRequiredWarning] = useState<
    boolean | undefined
  >(!eventTagToChange?.id);
  const onNameChange = (e: any) => {
    const _name = rmSpaces((e.target.value as string | undefined) || "", true);

    setShowNameRequiredWarning(_name.length < 1);

    setName(_name);
  };

  const [statusEnabled, setStatusEnabled] = useState(
    eventTagToChange?.id ? eventTagToChange.status === "enable" : true
  );

  const [isClicked, setIsClicked] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const clearState = () => {
    setName("");
    setShowNameRequiredWarning(true);

    setIsClicked(false);
    setIsActionInProgress(false);
  };

  const onButtonClick = async () => {
    const isBad = !!showNameRequiredWarning;

    if (isBad) {
      setIsClicked(true);
    } else {
      setIsActionInProgress(true);

      await updateEventTag({
        eventTagId: eventTagToChange?.id,
        status: getStatus(statusEnabled),
        name,
      });

      submit(null, { method: "post", action: "/event-tags" });

      clearState();

      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {eventTagToChange?.id ? "Update" : "Create"} event tag
          </h3>
        </div>

        <br />

        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <label className="block text-sm font-medium text-gray-700">
              Name
              <input
                value={name}
                onChange={onNameChange}
                disabled={isActionInProgress || !!eventTagToChange?.id}
                type="text"
                autoComplete="address-level2"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
              {isClicked && showNameRequiredWarning && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  The name field is required
                </p>
              )}
            </label>
          </div>

          <div className="col-span-12">
            <Switch.Group
              as="div"
              className="flex items-center justify-between"
            >
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Status
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  enable or disable
                </Switch.Description>
              </span>
              <Switch
                checked={statusEnabled}
                onChange={setStatusEnabled}
                className={classNames(
                  statusEnabled ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    statusEnabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>

        <br />

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            onClick={onButtonClick}
            disabled={
              isActionInProgress ||
              (!!eventTagToChange?.id &&
                ((eventTagToChange.status === "enable" && statusEnabled) ||
                  (eventTagToChange.status === "disable" && !statusEnabled)))
            }
          >
            {eventTagToChange?.id ? "Update" : "Create"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={onClose}
            disabled={isActionInProgress}
          >
            Cancel
          </button>
        </div>
      </>
    </Modal>
  );
};
