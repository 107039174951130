/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  CalendarIcon,
  DocumentTextIcon,
  TagIcon,
} from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  FolderIcon,
  XMarkIcon,
  FireIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { NavLink, Outlet, redirect } from "react-router-dom";

import { checkAuth, logout } from "../api/backend";
import { companyLogo } from "../constants";
import { classNames } from "../utils/helpers";
import { useAuthCheck } from "../utils/hooks/auth-check";

const navigation = [
  {
    name: "Projects",
    href: "/projects",
    icon: FolderIcon,
  },
  {
    name: "Projects applications",
    href: "/projects-applications",
    icon: DocumentTextIcon,
  },
  {
    name: "Events",
    href: "/events",
    icon: CalendarIcon,
  },
  {
    name: "Event tags",
    href: "/event-tags",
    icon: TagIcon,
  },
  {
    name: "Complaints",
    href: "/complaints",
    icon: FireIcon,
  },
];

export const Layout = () => {
  const submit = useAuthCheck();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(-1); // todo

  const navigationElement = (isMobile: boolean) =>
    navigation.map((item, i) => (
      <NavLink
        key={item.name}
        to={item.href}
        className={({ isActive }) => {
          if (isActive) setCurrentItem(i);

          return isMobile
            ? classNames(
                currentItem === i
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
              )
            : classNames(
                currentItem === i
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              );
        }}
      >
        <item.icon
          className={
            isMobile
              ? classNames(
                  currentItem === i
                    ? "text-gray-500"
                    : "text-gray-400 group-hover:text-gray-500",
                  "mr-4 flex-shrink-0 h-6 w-6"
                )
              : classNames(
                  currentItem === i
                    ? "text-gray-500"
                    : "text-gray-400 group-hover:text-gray-500",
                  "mr-3 flex-shrink-0 h-6 w-6"
                )
          }
          aria-hidden="true"
        />
        {item.name}
      </NavLink>
    ));

  const onLogout = async () => {
    await logout();

    submit(null, { method: "post", action: "/login" });
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <NavLink
                        to="/"
                        className={({ isActive }) => {
                          if (isActive) setCurrentItem(-1);

                          return "";
                        }}
                      >
                        <img
                          className="h-8 w-auto"
                          src={companyLogo.src}
                          alt={companyLogo.alt}
                        />
                      </NavLink>
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigationElement(true)}
                    </nav>
                  </div>
                  <div
                    className="mt-auto space-y-1 pt-10 cursor-pointer"
                    onClick={onLogout}
                  >
                    <span className="group flex items-center border-l-4 border-transparent py-2 px-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                      <ArrowLeftOnRectangleIcon
                        className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Logout
                    </span>
                  </div>
                  <br />
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <NavLink
                  to="/"
                  className={({ isActive }) => {
                    if (isActive) setCurrentItem(-1);

                    return "";
                  }}
                >
                  <img
                    className="h-8 w-auto"
                    src={companyLogo.src}
                    alt={companyLogo.alt}
                  />
                </NavLink>
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {navigationElement(false)}
              </nav>

              <div
                className="block w-full flex-shrink-0 cursor-pointer"
                onClick={onLogout}
              >
                <span className="group flex items-center border-l-4 border-transparent py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                  <ArrowLeftOnRectangleIcon
                    className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Logout
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="m-6">
              <h1 className="text-2xl font-semibold text-gray-900">
                Burn Admin
              </h1>
              <br />
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const check = async () => {
  if (!(await checkAuth())) {
    return redirect(`/login`);
  }
  return redirect(`/`);
};

Layout.action = async () => {
  return check();
};

Layout.loader = async () => {
  return {};
};
