import { BASE_API_URL } from "../../constants";
import { transformToOnlyStringObject } from "../../utils/helpers";
import { EventStatus, Sorting, WithPagination } from "./types";

type EventsFilter = Partial<{
  eventIds?: string[]; // number[];
  logo?: string;
  companyName?: string;
  companyAddress?: string;
  description?: string;
  statuses?: EventStatus[] | string;
  startDateMin?: string;
  startDateMax?: string;
  endDateMin?: string;
  endDateMax?: string;
  createdAtMin?: string;
  createdAtMax?: string;
  updatedAtMin?: string;
  updatedAtMax?: string;
  twitter?: string;
  sorting?: Sorting;
  offset?: string;
  limit?: string;
}>;

export type Event = {
  id: number;
  companyName: string; // from broxus api
  description: string;
  logo: string; // file name
  twitter: string;
  companyAddress: string; // venom wallet address
  createdAt: string; // date-time
  updatedAt: string; // date-time
  startDate: string; // date-time
  endDate: string; // date-time
  status: EventStatus;
};

export const getEvents = async (
  params: EventsFilter | undefined = {} as EventsFilter
) => {
  const apiUrl = `${BASE_API_URL}/api/events/filter`;

  // Status 'past' means that event has ended, but we don't have this status in the backend, and it's actually 'active'
  // Status can be 1 at the same time, overwise we need to rework this logic
  // 1. If we have 'past' status, we need to remove it and change to 'active' in request, add endDateMax to today and set status to 'past' in received data
  // 2. If we have 'active' status, we need to add endDateMin to today
  // 3. In other cases we don't need to change anything

  const filtersWithoutPast = params?.statuses?.includes("past")
    ? { ...params, statuses: ["active"], endDateMax: new Date().toISOString() }
    : params.statuses?.includes("active")
    ? { ...params, endDateMin: new Date().toISOString() }
    : params;

  try {
    const _params = {
      sorting: "createdAt_desc",
      ...filtersWithoutPast,
      //
    };
    const response = await fetch(
      `${apiUrl}?${new URLSearchParams(transformToOnlyStringObject(_params))}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      }
    );
    if (response?.ok) {
      const json: WithPagination<Event[]> = await response.json();

      const { data, totalNumber, limit, offset } = json;

      // loop through each event and update status if necessary
      if (params?.statuses?.includes("past")) {
        data.forEach((event) => {
          event.status = "past";
        });
      } else if (!params?.statuses) {
        const currentTime = new Date().setHours(0, 0, 0, 0);
        data.forEach((event) => {
          const eventEndTime = new Date(event.endDate!).getTime();
          if (eventEndTime <= currentTime && event.status === "active") {
            event.status = "past";
          }
        });
      }
      console.log(`[events] Result::`, json);

      return {
        data,
        totalNumber,
        limit,
        offset,
      };
    }
  } catch (e) {
    console.error(e);
  }
};

// type CreateEvent = {
//   logo: File; // logo file string($binary)
//   companyAddress: string; // venom wallet address
//   description: string;
//   startDate: string; // date-time
//   endDate: string; // date-time
//   twitter?: string;
//   tags?: string[]; // array of event tags
// };
// const createEvent = async (params: CreateEvent) => {
//   const apiUrl = `${BASE_API_URL}/api/events/event`;

//   try {
//     const body = JSON.stringify(params);
//     const response = await fetch(apiUrl, {
//       method: "POST",
//       headers: {
//         "content-type": "application/json",
//       },
//       body,
//     });
//     if (response?.ok) {
//       const json: Event = await response.json();

//       console.log(`[create event] Result::`, json?.id ? "Ok" : "Not ok");

//       return json;
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };

export type UpdateEvent = {
  eventId: number; // or create (if undefined)
  status: EventStatus;
};
export const updateEvent = async (params: UpdateEvent) => {
  const apiUrl = `${BASE_API_URL}/api/events/event/status`;

  try {
    const body = JSON.stringify(params);
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
      body,
    });
    if (response?.ok) {
      const json: Event = await response.json();

      console.log(`[update event] Result::`, json?.id ? "Ok" : "Not ok");

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

export const getEventFileFolder = (fileName: string) =>
  `${BASE_API_URL}/api/events/event/file/${fileName}`;

// type GetEventFile = {
//   fileName: string;
// };
// const getEventFile = async ({ fileName }: GetEventFile) => {
//   const apiUrl = `${BASE_API_URL}/api/events/event/file/${fileName}`;

//   try {
//     const response = await fetch(apiUrl, {
//       method: "PUT",
//       headers: {
//         "content-type": "application/json",
//       },
//     });
//     if (response?.ok) {
//       const json = await response.json();

//       console.log(`[event file] Result::`, json ? "Ok" : "Not ok", json);

//       return json;
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };
