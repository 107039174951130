import { BASE_API_URL } from "../../constants";
import { transformToOnlyStringObject } from "../../utils/helpers";
import { EventTagStatus, Sorting, WithPagination } from "./types";

type EventTagsFilter = Partial<{
  eventTagIds: string[]; // number[];
  name: string;
  statuses: EventTagStatus[];
  createdAtMin: string; // date-time
  createdAtMax: string; // date-time
  updatedAtMin: string; // date-time
  updatedAtMax: string; // date-time
  sorting: Sorting;
  offset: string; // number;
  limit: string; // number;
}>;

export type EventTag = {
  createdAt: string; // date-time
  updatedAt: string; // date-time
  id: number;
  name: string;
  status: EventTagStatus;
};

export const getEventTags = async (params?: EventTagsFilter) => {
  const apiUrl = `${BASE_API_URL}/api/event-tags/filter`;

  try {
    const _params: EventTagsFilter = {
      sorting: "createdAt_desc",
      ...params,
      //
      // limit: "1000000000", // fix pagination
    };
    const response = await fetch(
      `${apiUrl}?${new URLSearchParams(transformToOnlyStringObject(_params))}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      }
    );
    if (response?.ok) {
      const json: WithPagination<EventTag[]> = await response.json();

      console.log(`[event-tags] Result::`, json);

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

export type UpdateEventTag = {
  eventTagId?: number; // or create (if undefined)
  name: string;
  status: EventTagStatus;
};
export const updateEventTag = async ({
  eventTagId,
  name,
  status,
}: UpdateEventTag) => {
  const apiUrl = `${BASE_API_URL}/api/event-tags/event-tag`;

  try {
    const body = JSON.stringify({
      eventTagId,
      name,
      status,
    });
    const response = await fetch(apiUrl, {
      method: eventTagId === undefined ? "POST" : "PUT",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
      body,
    });
    if (response?.ok) {
      const json: EventTag = await response.json();

      console.log(`[event-tag] Result::`, json?.id ? "Ok" : "Not ok");

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};
