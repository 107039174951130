import { Dialog } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSubmit } from "react-router-dom";

import { deleteProject } from "../../api/backend/projects";

import { Modal } from "./common";

export const RemoveProject = ({
  onClose,
  id,
}: {
  onClose: () => void;
  id: number;
}) => {
  const submit = useSubmit();

  const [isActionInProgress, setIsActionInProgress] = useState(false);

  const onButtonClick = async () => {
    setIsActionInProgress(true);

    await deleteProject(id);

    submit(null, { method: "delete", action: "/projects" });

    setIsActionInProgress(false);

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Are you sure you want to remove it?
          </Dialog.Title>
          {/* <div className="mt-2">
      <p className="text-sm text-gray-500">
       text
      </p>
    </div> */}
        </div>
      </div>

      <br />

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          onClick={onButtonClick}
          disabled={isActionInProgress}
        >
          Remove
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          onClick={onClose}
          disabled={isActionInProgress}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
