import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const currentClasses = "z-10 bg-indigo-50 border-indigo-500 text-indigo-600";
const defaultClasses =
  "bg-white border-gray-300 text-gray-500 hover:bg-gray-50";

type PaginationProps = {
  currentPage: number;
  perPage: number;
  total: number;
  onClick: (page: number) => void;
};
export const Pagination = ({
  currentPage,
  perPage,
  total,
  onClick,
}: PaginationProps) => {
  const totalPages = Math.round(
    Math.floor(total / perPage) + (total % perPage ? 1 : 0)
  );

  const numbersArr = new Array(totalPages).fill("").map((p, i) => i + 1);

  const half = 2;

  const start = Math.max(0, Math.floor(currentPage - half));
  const end = start + half * 2 + 1;

  const pages =
    totalPages > 6
      ? numbersArr.slice(
          end <= totalPages ? start : Math.max(totalPages - (half * 2 + 1), 0),
          Math.min(end, totalPages)
        )
      : numbersArr;

  const onPreviousClick = () => {
    const previous = currentPage - 1;
    if (previous >= 0) onClick(previous * perPage);
  };
  const onPageClick = (page: number) => {
    onClick((page - 1) * perPage);
  };
  const onNextClick = () => {
    const next = currentPage + 1;
    if (next < totalPages) onClick(next * perPage);
  };

  const from = currentPage * perPage + 1;
  const to = Math.min((currentPage + 1) * perPage, total);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <span
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={currentPage ? onPreviousClick : undefined}
          style={
            !currentPage
              ? {
                  visibility: "hidden",
                  cursor: "default",
                }
              : {
                  cursor: "pointer",
                }
          }
        >
          Previous {currentPage} {JSON.stringify(!!currentPage)}
        </span>
        <span
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={currentPage + 1 < totalPages ? onNextClick : undefined}
          style={
            currentPage + 1 >= totalPages
              ? {
                  visibility: "hidden",
                  cursor: "default",
                }
              : {
                  cursor: "pointer",
                }
          }
        >
          Next
        </span>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from}</span> to{" "}
            <span className="font-medium">{to}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
              <ChevronLeftIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={currentPage ? onPreviousClick : undefined}
                style={
                  !currentPage
                    ? {
                        visibility: "hidden",
                        cursor: "default",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
              />
            </span>

            {pages.map((page) => {
              return (
                <span
                  key={page}
                  aria-current="page"
                  className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20 ${
                    currentPage + 1 === page ? currentClasses : defaultClasses
                  }`}
                  onClick={
                    !Number.isNaN(+page) ? () => onPageClick(page) : undefined
                  }
                  style={
                    currentPage + 1 === page
                      ? {
                          cursor: "default",
                        }
                      : {
                          cursor: "pointer",
                        }
                  }
                >
                  {page}
                </span>
              );
            })}

            <span className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
              <ChevronRightIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={currentPage + 1 < totalPages ? onNextClick : undefined}
                style={
                  currentPage + 1 >= totalPages
                    ? {
                        visibility: "hidden",
                        cursor: "default",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
              />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};
