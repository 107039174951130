import { useEffect, useState } from "react";
import { redirect, useLocation, useSubmit } from "react-router-dom";

import { checkAuth, login as loginAsAdmin } from "../../api/backend";
import { companyLogo } from "../../constants";
import { rmSpaces } from "../../utils/helpers";

export const Login = () => {
  const location = useLocation();
  const submit = useSubmit();

  useEffect(() => {
    if (location.pathname.includes("login")) {
      submit(null, { method: "post", action: "/" });
    }
  }, [location.pathname, submit]);

  const [login, setLogin] = useState("");
  const [showLoginRequiredWarning, setShowLoginRequiredWarning] = useState<
    boolean | undefined
  >(true);
  const onLoginChange = (e: any) => {
    const _login = rmSpaces((e.target.value as string | undefined) || "", true);

    setShowLoginRequiredWarning(_login.length < 1);

    setLogin(_login);
  };

  const [password, setAddress] = useState("");
  const [showPasswordRequiredWarning, setShowPasswordRequiredWarning] =
    useState<boolean | undefined>(true);
  const onPasswordChange = (e: any) => {
    const _password = rmSpaces((e.target.value as string | undefined) || "");

    setShowPasswordRequiredWarning(_password.length < 1);

    setAddress(_password);
  };

  const [isClicked, setIsClicked] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const clearState = () => {
    setLogin("");
    setShowLoginRequiredWarning(true);

    setAddress("");
    setShowPasswordRequiredWarning(true);

    setIsClicked(false);
    setIsActionInProgress(false);
  };

  const onButtonClick = async () => {
    const isBad = showLoginRequiredWarning || showPasswordRequiredWarning;

    if (isBad) {
      setIsClicked(true);
    } else {
      setIsActionInProgress(true);

      await loginAsAdmin({
        login,
        password,
      });

      clearState();

      submit(null, { method: "post", action: "/" });
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={companyLogo.src}
            alt={companyLogo.alt}
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">sub text</p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="login"
                  className="block text-sm font-medium text-gray-700"
                >
                  Login
                </label>
                <div className="mt-1">
                  <input
                    value={login}
                    onChange={onLoginChange}
                    disabled={isActionInProgress}
                    id="login"
                    name="login"
                    type="login"
                    autoComplete="login"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {isClicked && showLoginRequiredWarning && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      The login field is required
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    value={password}
                    onChange={onPasswordChange}
                    disabled={isActionInProgress}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {isClicked && showPasswordRequiredWarning && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      The password field is required
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onButtonClick}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const check = async () => {
  if (await checkAuth()) {
    return redirect(`/`);
  }
  return redirect(`/login`);
};

Login.action = async () => {
  return check();
};

Login.loader = async () => {
  return {};
};
