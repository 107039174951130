import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Complaints } from "./components/complaints";

import { EventTags } from "./components/event-tags";
import { EventDetails } from "./components/eventdetails";
import { Events } from "./components/events";
import { Layout } from "./components/layout";
import { Login } from "./components/modals/login";
import { Projects } from "./components/projects";
import { ProjectsApplications } from "./components/projects-application";

function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
      loader: Login.loader,
      action: Login.action,
      errorElement: "Login Error",
    },

    {
      path: "/",
      element: <Layout />,
      loader: Layout.loader,
      action: Layout.action,
      errorElement: "Error",

      children: [
        {
          path: "/projects",
          loader: Projects.loader,
          action: Projects.action,
          element: <Projects />,
          errorElement: "Projects Error",
        },
        {
          path: "/projects-applications",
          loader: ProjectsApplications.loader,
          element: <ProjectsApplications />,
          errorElement: "Projects Error",
        },
        {
          path: "/events",
          loader: Events.loader,
          action: Events.action,
          element: <Events />,
          errorElement: "Events Error",
        },
        {
          path: "/events/:id", // <-- new route with dynamic parameter :id
          loader: ({ params }) => EventDetails.loader(params.id),
          action: EventDetails.action,
          element: <EventDetails />,
          errorElement: "EventDetails Error",
        },
        {
          path: "/event-tags",
          loader: EventTags.loader,
          action: EventTags.action,
          element: <EventTags />,
          errorElement: "EventTags Error",
        },
        {
          path: "/complaints",
          loader: Complaints.loader,
          action: Complaints.action,
          element: <Complaints />,
          errorElement: "Complaints Error",
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
