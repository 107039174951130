import { unparse } from "papaparse";

import { BASE_INDEXER_API_URL, INDEXER_API_KEY } from "../../constants";

export type Project = {
  name?: string;
  accountAddr: string;
};
export type UpdateProjects = {
  projectsData: Project[];
};
export const updateProjects = async ({ projectsData }: UpdateProjects) => {
  const apiUrl = `${BASE_INDEXER_API_URL}/api/v1/setup/projects/set?apiKey=${INDEXER_API_KEY}`;

  try {
    const csvFile = unparse([
      ["account_addr", "name"],
      ...projectsData.map(({ accountAddr, name }) => [accountAddr, name]),
    ]);

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "content-type": "application/octet-stream",
        accept: "text/plain",
      },
      body: csvFile,
    });
    if (response?.ok) {
      const text = await response.text();

      console.log(`[set projects] Result::`, text);

      return text;
    }
  } catch (e) {
    console.error(e);
  }
};

export const getProjects = async () => {
  const apiUrl = `${BASE_INDEXER_API_URL}/api/v1/projects/list`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    });
    if (response?.ok) {
      const json = await response.json();

      console.log(`[list projects] Result::`, json);

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};
