import { BASE_API_URL } from "../../constants";
import { Tokens } from "./types";

export type Login = {
  login: string;
  password: string;
};
export const login = async ({ login, password }: Login) => {
  const apiUrl = `${BASE_API_URL}/api/auth/log-in`;

  try {
    const body = JSON.stringify({
      login,
      password,
    });
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
      body,
    });
    if (response?.ok) {
      const json: Tokens = await response.json();

      console.log(
        `[login] Result::`,
        json?.access_token && json?.refresh_token ? "Ok" : "Not ok"
      );

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

export const logout = async () => {
  const apiUrl = `${BASE_API_URL}/api/auth/log-out`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      credentials: "include",
    });
    if (response?.ok) {
      return true;
    }
  } catch (e) {
    console.error(e);
  }
};

export const checkAuth = async () => {
  const apiUrl = `${BASE_API_URL}/api/auth/check`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      credentials: "include",
    });
    if (response?.ok) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const getProfile = async () => {
  const apiUrl = `${BASE_API_URL}/api/auth/profile`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
      credentials: "include",
    });
    if (response?.ok) {
      const json = await response.json();

      console.log(`[profile] Result::`, json);

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};
