import { Link, useLoaderData, useRevalidator } from "react-router-dom";

import {
  Complaint,
  Event,
  getComplaints,
  getEvents,
  updateEvent,
} from "../api/backend";
import { WithPagination } from "../api/backend/types";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";
import { getStatusClass } from "../utils/event";
import { disablesStatusIds, eventStatuses } from "./modals/update-event";
import { useState } from "react";
import { Loader } from "./Loader";
import { EventComplaints } from "./eventComplaints";

export const EventDetails = () => {
  const data = useLoaderData() as {
    eventsData: WithPagination<Event[]>;
    complaintsData: WithPagination<Complaint[]>;
  };
  const [searchParams] = useSearchParams();

  const backToComplaints = searchParams.get("complaints");

  const {
    id,
    companyName,
    description,
    logo,
    twitter,
    companyAddress,
    createdAt,
    updatedAt,
    startDate,
    endDate,
    status,
  } = data.eventsData?.data[0];

  const [statusToChange, setStatusToChange] = useState(status);
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  let revalidator = useRevalidator();

  const pages = [
    { name: "Events", href: "/events", current: false },
    { name: id, href: "#", current: true },
  ];

  const onStatusChange = (e: any) => {
    const id = e.target.value as string | undefined;
    if (!id) return;

    setStatusToChange(
      eventStatuses.find((eventStatus) => eventStatus.id === id)?.id!
    );
  };

  const onButtonClick = async () => {
    setIsActionInProgress(true);

    await updateEvent({
      eventId: id,
      status: statusToChange,
    });

    revalidator.revalidate();

    setIsActionInProgress(false);
  };

  return (
    <>
      <div className="flex justify-between align-middle">
        <nav className="flex" aria-label="Breadcrumb">
          <ul className="flex items-center space-x-4 mb-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </nav>
        {backToComplaints && (
          <Link to="/complaints" className="text-indigo-600 hover:underline">
            Back to complaints
          </Link>
        )}
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex flex-col lg:flex-row">
          <div className="w-full">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {companyName} - event information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-4 lg:mr-4">
              {description}
            </p>
          </div>
          {logo && (
            <div className="w-fit flex lg:justify-end ">
              <div className="lg:max-w-1/2">
                <img
                  className="rounded-lg h-auto w-auto"
                  src={logo}
                  alt="logo"
                />
              </div>
            </div>
          )}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">id</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {id}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {companyAddress}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {createdAt}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated at</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {updatedAt}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Start date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {startDate}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">End date</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {endDate}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 flex flex-col sm:flex-row items-center align-middle text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span
                  className={getStatusClass({
                    status,
                    additionalClasses:
                      "inline-flex rounded-full h-fit px-2 text-xs font-semibold leading-5",
                  })}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {status}
                </span>

                {!(status === "past") && (
                  <>
                    <fieldset>
                      <div
                        className="flex space-x-4 ml-10 mr-5"
                        onChange={onStatusChange}
                      >
                        {eventStatuses.map(({ id, title }) => (
                          <div key={id} className="flex items-center">
                            <input
                              disabled={disablesStatusIds.includes(id)}
                              defaultChecked={statusToChange === id}
                              value={id}
                              id={id}
                              name="event"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              style={
                                disablesStatusIds.includes(id)
                                  ? {
                                      cursor: "default",
                                    }
                                  : {
                                      cursor: "pointer",
                                    }
                              }
                            />
                            <label
                              htmlFor={id}
                              className="ml-3 block text-sm font-medium text-gray-700"
                              style={
                                disablesStatusIds.includes(id)
                                  ? {
                                      cursor: "default",
                                    }
                                  : {
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                    <button
                      type="button"
                      className="inline-flex w-fit justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={onButtonClick}
                      disabled={isActionInProgress || statusToChange === status}
                    >
                      {isActionInProgress && <Loader inline />}
                      Update status
                    </button>
                  </>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Twitter</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a
                  href={"https://t.me/" + twitter.trim().replace(/^@/, "")}
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  {twitter}
                </a>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Event complaints
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <EventComplaints eventId={id} />
              </dd>
              <div id="complaints" />
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

EventDetails.action = async () => {
  // return redirect(`/events`);
};

const loader = async ({
  eventId,
}: Partial<{
  eventId?: string;
}>) => {
  const eventsData = await getEvents({
    eventIds: eventId ? [eventId] : undefined,
  });

  if (!eventsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  const complaintsData = await getComplaints({
    eventIds: eventId ? [eventId] : undefined,
    offset: "0",
    limit: "100",
  });

  console.log("complaintsData", complaintsData);

  return { eventsData, complaintsData };
};

EventDetails.loader = async (id?: string) => {
  return loader({ eventId: id });
};
