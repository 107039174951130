import { cutAddress } from "./helpers";

export const getStatusClass = ({
  status,
  additionalClasses = "",
}: {
  status: string;
  additionalClasses?: string;
}) => {
  return `${additionalClasses} ${
    status === "active"
      ? "text-green-800 bg-green-100"
      : status === "approved"
      ? "text-yellow-800 bg-green-100"
      : status === "new"
      ? "text-yellow-800 bg-yellow-100"
      : status === "moderation"
      ? "text-yellow-800 bg-yellow-100"
      : status === "rejected"
      ? "text-red-800 bg-red-100"
      : "text-gray-800 bg-gray-100"
  }`;
};

export type FieldType =
  | "address"
  | "date"
  | "url"
  | "status"
  | "text"
  | "email";

export const parseFieldValue = ({
  field,
  fieldType = "text",
  url,
}: {
  field: string;
  fieldType?: FieldType;
  url?: string;
}) => {
  switch (fieldType) {
    case "address":
      return cutAddress(field);

    case "email":
      return (
        <a
          href={`mailto:${field}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
          }}
          className="text-blue-600 hover:text-blue-600/[.6]"
        >
          {field}
        </a>
      );

    case "url":
      return (
        <a
          href={url || field}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
          }}
          className="text-blue-600 hover:text-blue-600/[.6]"
        >
          {field}
        </a>
      );

    case "date":
      return new Date(field).toLocaleString();

    case "status":
      return (
        <span
          className={getStatusClass({
            status: field,
            additionalClasses:
              "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
          })}
        >
          {field}
        </span>
      );

    case "text":
    default:
      return field;
  }
};
