import { BASE_API_URL } from "../../constants";
import { transformToOnlyStringObject } from "../../utils/helpers";
import { Sorting, WithPagination } from "./types";

export type ProjectsFilter = Partial<{
  eventIds: string[]; // number[];
  logo: string;
  walletAddres: string;
  endDateMin: string;
  endDateMax: string;
  createdAtMin: string;
  createdAtMax: string;
  updatedAtMin: string;
  updatedAtMax: string;
  sorting: Sorting;
  offset: string;
  limit: string;
}>;

export interface Project {
  createdAt?: string; // $date-time format
  updatedAt?: string; // $date-time format
  id?: number;
  walletAddress?: string;
  name?: string;
  logo?: string;
}

export const getProjects = async (
  params: ProjectsFilter | undefined = {} as ProjectsFilter
) => {
  const apiUrl = `${BASE_API_URL}/api/projects/filter`;

  try {
    const _params: ProjectsFilter = {
      sorting: "id_desc",
      ...params,
      //
    };
    const response = await fetch(
      `${apiUrl}?${new URLSearchParams(transformToOnlyStringObject(_params))}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      }
    );
    if (response?.ok) {
      const json: WithPagination<Project[]> = await response.json();

      console.log(`[events] Result::`, json);

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

export type CreateProject = {
  logo?: File; // logo file string($binary)
  walletAddress: string; // venom wallet address
  name: string;
};

export const createProject = async (params: CreateProject) => {
  const apiUrl = `${BASE_API_URL}/api/projects/project`;

  const formData = new FormData();
  if (params.logo) {
    formData.append("logo", params.logo, params.logo.name);
  }
  formData.append("name", params.name);
  formData.append("walletAddress", params.walletAddress);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        // "content-type": "application/json",
      },
      credentials: "include",
      body: formData,
    });
    if (response?.ok) {
      const json: Project = await response.json();

      console.log(`[create event] Result::`, json?.name ? "Ok" : "Not ok");

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

export const syncProjects = async () => {
  const apiUrl = `${BASE_API_URL}/api/projects/synchronization`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    });
    if (response?.ok) {
      console.log(`[sync projects] Result::ok`);
    }
  } catch (e) {
    console.error(e);
  }
};

export const deleteProject = async (id: number) => {
  const apiUrl = `${BASE_API_URL}/api/projects/project/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    });
    if (response?.ok) {
      console.log(`[delete project] ok`);

      return;
    }
  } catch (e) {
    console.error(e);
  }
};

export type UpdateProject = {
  logo?: File; // logo file string($binary)
  name?: string;
  projectId: number;
  isItNecessaryToRemoveTheName?: boolean;
  isItNecessaryToRemoveTheLogo?: boolean;
};

export const updateProject = async (params: UpdateProject) => {
  const apiUrl = `${BASE_API_URL}/api/projects/project`;

  console.log(`[update event] params::`, params);

  const formData = new FormData();
  formData.append("projectId", params.projectId.toString());
  if (params.logo) {
    formData.append("logo", params.logo, params.logo.name);
  }
  if (params.isItNecessaryToRemoveTheLogo)
    formData.append(
      "isItNecessaryToRemoveTheLogo",
      params.isItNecessaryToRemoveTheLogo.toString()
    );

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        // "content-type": "application/json",
      },
      credentials: "include",
      body: formData,
    });
    if (response?.ok) {
      const json: Project = await response.json();

      console.log(`[update event] Result::`, json?.name ? "Ok" : "Not ok");

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};
