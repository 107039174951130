import { useEffect, useState } from "react";
import { Link, redirect, useLoaderData } from "react-router-dom";

import {
  Complaint,
  // getComplaintFileFolder,
  getComplaints,
} from "../api/backend";
import { ComplaintStatus, WithPagination } from "../api/backend/types";
import { getStatusClass } from "../utils/event";
import { cutAddress } from "../utils/helpers";
import { UpdateComplaint } from "./modals/update-complaint";
import { Pagination } from "./pagination";
import { Item, Select } from "./select";

const hideChangeButtonStatuses: ComplaintStatus[] = [];

const emptyItem: Item = {
  id: `__empty`,
  label: `Not selected`,
};

export const Complaints = () => {
  const loadedData = useLoaderData() as WithPagination<Complaint[]>;

  const [data, setData] = useState(loadedData);

  const [isChangeComplaintOpen, setIsChangeComplaintOpen] = useState(false);

  const [complaintToChange, setComplaintToChange] = useState<
    Complaint | undefined
  >();

  const onChangeButtonClick = (changeEventData: Complaint) => {
    setComplaintToChange(changeEventData);
    setIsChangeComplaintOpen(true);
  };

  const paginationInfo = {
    currentPage: Math.ceil(data.offset / data.limit), // 0...inf
    perPage: data.limit, // 1...inf
    total: data.totalNumber, // 0...inf
  };

  const onPagination = async (currentPage?: number) => {
    onButtonClick(currentPage);
  };

  const [selectedStatus, setSelectedStatus] = useState<Item | undefined>(
    emptyItem
  );

  const onButtonClick = async (currentPage?: number) => {
    const newData = await loader({
      complaintStatus:
        selectedStatus?.id === emptyItem.id ? undefined : selectedStatus?.label,
      currentPage,
    });

    setData(newData);
  };

  useEffect(() => {
    onButtonClick(paginationInfo.currentPage);
    // eslint-disable-next-line
  }, [selectedStatus]);

  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <span className="text-xl font-semibold text-gray-900">
            Complaints
          </span>
        </div>

        <br />

        <>
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                <>Get complaints</>
              </h3>
            </div>

            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-4">
                <Select
                  title="By status"
                  items={[emptyItem].concat(
                    (["new", "approved", "rejected"] as ComplaintStatus[]).map(
                      (status) => ({
                        id: status,
                        label: status,
                      })
                    )
                  )}
                  selectedItem={selectedStatus}
                  setSelectedItem={setSelectedStatus}
                />
              </div>

              <div className="col-span-4">
                &nbsp;
                <div className="text-sm font-medium text-gray-700 flex content-end">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={() => setSelectedStatus(emptyItem)}
                  >
                    Clear filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>

        <div className="mt-8 flex flex-col">
          <div className="-my-2  overflow-x-auto ">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        EventID
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4"
                      >
                        Comment
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.data?.map((complaintData) => {
                      const { id, eventId, status, walletAddress, comment } =
                        complaintData;

                      return (
                        <tr key={id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-600 hover:underline sm:pl-6 lg:pl-8">
                            <Link to={`/events/${eventId}?complaints=true`}>
                              {eventId || "no name"}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {cutAddress(walletAddress)}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            style={{
                              wordBreak: "break-all",
                              fontFamily: "monospace, monospace",
                            }}
                          >
                            <span
                              className={getStatusClass({
                                status,
                                additionalClasses:
                                  "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                              })}
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {status}
                            </span>
                          </td>
                          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 w-1/4 sm:pl-6 lg:pl-8">
                            <div className="text-ellipsis max-w-[400px] w-full">
                              {comment}
                            </div>
                          </td>

                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            {!hideChangeButtonStatuses.includes(status) ? (
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                  onChangeButtonClick(complaintData);
                                }}
                                style={{
                                  opacity: "80%",
                                }}
                              >
                                Change status
                              </button>
                            ) : (
                              <>&nbsp;</>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        {paginationInfo.total > paginationInfo.perPage && (
          <Pagination
            currentPage={paginationInfo.currentPage}
            perPage={paginationInfo.perPage}
            total={paginationInfo.total}
            onClick={onPagination}
          />
        )}
      </div>

      {isChangeComplaintOpen && complaintToChange && (
        <UpdateComplaint
          name={complaintToChange.walletAddress}
          description={complaintToChange.comment}
          fields={[
            { key: "id", value: complaintToChange.id.toString() },
            { key: "eventId", value: complaintToChange.eventId.toString() },
            {
              key: "walletAddress",
              value: complaintToChange.walletAddress,
              type: "address",
            },
            { key: "status", value: complaintToChange.status, type: "status" },
            // todo tags
            {
              key: "createdAt",
              value: complaintToChange.createdAt,
              type: "date",
            },
            {
              key: "updatedAt",
              value: complaintToChange.updatedAt,
              type: "date",
            },
          ]}
          onClose={() => {
            setIsChangeComplaintOpen(false);
            setComplaintToChange(undefined);

            onPagination();
          }}
          complaintToChange={complaintToChange}
        />
      )}
    </>
  );
};

Complaints.action = async () => {
  return redirect(`/complaints`);
};

const loader = async ({
  complaintStatus,
  currentPage = 0,
}: Partial<{
  complaintStatus: string;
  currentPage?: number;
}>) => {
  const complaintsData = await getComplaints({
    statuses: complaintStatus,
    offset: currentPage.toString(),
  });
  console.log("complaintsData", complaintsData);

  if (!complaintsData) {
    throw new Response("", {
      status: 404,
      statusText: "Not Found",
    });
  }

  return complaintsData;
};

Complaints.loader = async () => {
  return loader({});
};
