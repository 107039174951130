import { Project } from "../api/indexer";

const addressRegexp = new RegExp(/^([-+]?\d)+:[0-9a-fA-F]{64}$/);

const notOkAddresses: string[] = [
  "0:0000000000000000000000000000000000000000000000000000000000000000",
];

export const rmSpaces = (str: string, onlyMultiple?: boolean) => {
  const regExp = new RegExp(/\b\s+\b/g);
  return str.trimStart().replaceAll(regExp, onlyMultiple ? " " : "");
};

export const checkIsAddressValid = (address: string) => {
  return addressRegexp.test(address) && !notOkAddresses.includes(address);
};

export const removeProject = (
  projects: Project[],
  projectToRemove: Project
) => {
  const projectId = projects.findIndex(
    ({ accountAddr, name }) =>
      accountAddr === projectToRemove.accountAddr ||
      name === projectToRemove.name
  );

  return [...projects.slice(0, projectId), ...projects.slice(projectId + 1)];
};

export const transformToOnlyStringObject = (params: {
  [queryKey: string]: string | string[];
}) => {
  return Object.fromEntries(
    Object.entries(params)
      .filter(([key, value]) =>
        Array.isArray(value) ? !!value.length : !!value
      )
      .reduce((r, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((iValue) => {
            r.push([key, iValue]);
          });
        }

        r.push([key, value as string]);

        return r;
      }, [] as [string, string][])
  );
};

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const cutAddress = (address: string) => {
  return `${address.split(":")?.[0]}:${address
    .split(":")?.[1]
    .slice(0, 4)}...${address.split(":")?.[1]?.slice(-4)}`;
};
