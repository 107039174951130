import { BASE_API_URL } from "../../constants";
import { transformToOnlyStringObject } from "../../utils/helpers";
import { ComplaintStatus, Sorting, WithPagination } from "./types";

type ComplaintsFilter = Partial<{
  eventComplaintIds?: string[]; // array of integers
  eventIds?: string[]; // array of integers
  comment?: string; // case-insensitive substring search
  walletAddress?: string; // case-insensitive substring search
  createdAtMin?: string; // lower time limit (inclusive)
  createdAtMax?: string; // upper time limit (not inclusive)
  updatedAtMin?: string; // lower time limit (inclusive)
  updatedAtMax?: string; // upper time limit (not inclusive)

  statuses: ComplaintStatus[] | string;
  sorting: Sorting;
  offset: string;
  limit: string;
}>;

export type Complaint = {
  createdAt: string; // format: date-time
  updatedAt: string; // format: date-time
  id: number;
  status: "new" | "approved" | "rejected";
  eventId: number;
  comment: string;
  walletAddress: string;
};

export const getComplaints = async (
  params: ComplaintsFilter | undefined = {} as ComplaintsFilter
) => {
  const apiUrl = `${BASE_API_URL}/api/event-complaints/filter`;

  try {
    const _params: ComplaintsFilter = {
      sorting: "createdAt_desc",
      ...params,
      //
    };
    const response = await fetch(
      `${apiUrl}?${new URLSearchParams(transformToOnlyStringObject(_params))}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          accept: "application/json",
        },
      }
    );
    if (response?.ok) {
      const json: WithPagination<Complaint[]> = await response.json();

      console.log(`[events] Result::`, json);

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

// type CreateEvent = {
//   logo: File; // logo file string($binary)
//   companyAddress: string; // venom wallet address
//   description: string;
//   startDate: string; // date-time
//   endDate: string; // date-time
//   twitter?: string;
//   tags?: string[]; // array of event tags
// };
// const createEvent = async (params: CreateEvent) => {
//   const apiUrl = `${BASE_API_URL}/api/events/event`;

//   try {
//     const body = JSON.stringify(params);
//     const response = await fetch(apiUrl, {
//       method: "POST",
//       headers: {
//         "content-type": "application/json",
//       },
//       body,
//     });
//     if (response?.ok) {
//       const json: Event = await response.json();

//       console.log(`[create event] Result::`, json?.id ? "Ok" : "Not ok");

//       return json;
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };

export type UpdateComplaint = {
  eventComplaintId: number;
  status: string;
  eventId: number;
  comment: string;
  walletAddress: string;
};

export const updateComplaint = async (params: UpdateComplaint) => {
  // /api/event-complaints/event-complaint
  const apiUrl = `${BASE_API_URL}/api/event-complaints/event-complaint`;

  try {
    const body = JSON.stringify(params);
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
      body,
    });
    if (response?.ok) {
      const json: Complaint = await response.json();

      console.log(`[update event] Result::`, json?.id ? "Ok" : "Not ok");

      return json;
    }
  } catch (e) {
    console.error(e);
  }
};

export const getComplaintFileFolder = (fileName: string) =>
  `${BASE_API_URL}/api/events/event/file/${fileName}`;

// type GetEventFile = {
//   fileName: string;
// };
// const getEventFile = async ({ fileName }: GetEventFile) => {
//   const apiUrl = `${BASE_API_URL}/api/events/event/file/${fileName}`;

//   try {
//     const response = await fetch(apiUrl, {
//       method: "PUT",
//       headers: {
//         "content-type": "application/json",
//       },
//     });
//     if (response?.ok) {
//       const json = await response.json();

//       console.log(`[event file] Result::`, json ? "Ok" : "Not ok", json);

//       return json;
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };
