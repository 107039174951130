import { XMarkIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSubmit } from "react-router-dom";

import { CreateProject, createProject } from "../../api/backend/projects";
import { checkIsAddressValid, rmSpaces } from "../../utils/helpers";
import { Modal } from "./common";

export const AddProject = ({ onClose }: { onClose: () => void }) => {
  const submit = useSubmit();

  const [name, setName] = useState("");
  const [showNameRequiredWarning, setShowNameRequiredWarning] = useState<
    boolean | undefined
  >(true);
  const [address, setAddress] = useState("");
  const [showAddressRequiredWarning, setShowAddressRequiredWarning] = useState<
    boolean | undefined
  >(true);
  const [showInvalidAddressWarning, setShowInvalidAddressWarning] = useState<
    boolean | undefined
  >();
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    null
  );
  const [file, setFile] = useState<File | null>(null);

  const onNameChange = (e: any) => {
    const _name = rmSpaces((e.target.value as string | undefined) || "", true);

    setShowNameRequiredWarning(_name.length < 1);

    setName(_name);
  };

  const onAddressChange = (e: any) => {
    const _address = rmSpaces((e.target.value as string | undefined) || "");

    setShowAddressRequiredWarning(_address.length < 1);

    setShowInvalidAddressWarning(!checkIsAddressValid(_address));

    setAddress(_address);
  };

  const [isClicked, setIsClicked] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const clearState = () => {
    setName("");
    setShowNameRequiredWarning(true);

    setAddress("");
    setShowAddressRequiredWarning(true);
    setShowInvalidAddressWarning(undefined);

    setIsClicked(false);
    setIsActionInProgress(false);
  };

  const onButtonClick = async () => {
    const addedProjectsData = {
      walletAddress: address,
      name,
      logo: file,
    };

    const isBad =
      showNameRequiredWarning ||
      showAddressRequiredWarning ||
      showInvalidAddressWarning;

    if (isBad) {
      setIsClicked(true);
    } else {
      setIsActionInProgress(true);

      await createProject(addedProjectsData as CreateProject);

      submit(null, { method: "post", action: "/projects" });

      clearState();

      onClose();
    }
  };

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];

    // Check MIME type
    const acceptedTypes = ["image/jpeg", "image/png", "image/svg+xml"];
    if (acceptedTypes.indexOf(file.type) === -1) {
      alert("Only JPEG, PNG, and SVG files are accepted");
      event.target.value = null; // clear the file input
      return;
    }

    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const clearImage = () => {
    setPreviewUrl(null);
    setFile(null);
  };

  return (
    <Modal onClose={onClose}>
      <>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Add project
          </h3>
        </div>

        <br />
        <label className="block text-sm font-medium text-gray-700">Logo</label>
        <div className="flex items-center justify-center h-20 w-20 rounded-lg border border-gray-300 hover:border-gray-500 hover:cursor-pointer border-dotted relative mb-5 group">
          {previewUrl ? (
            <>
              <div
                className="rounded-full bg-gray-100 hover:bg-gray-200 hover:text-gray-700 p-1 absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-gray-400 "
                onClick={clearImage}
              >
                <XMarkIcon className="h-3 w-3 " />
              </div>
              <div className="h-full w-full flex justify-center items-center overflow-hidden rounded-lg">
                <img
                  src={previewUrl as string}
                  className="object-fill h-full w-full"
                  alt="logo"
                />
              </div>
            </>
          ) : (
            <div
              className="group-hover:text-gray-700 p-1 text-gray-300"
              onClick={clearImage}
            >
              Upload
            </div>
          )}
          <input
            className="absolute left-0 text-[0] m-0 p-0 top-0 opacity-0 file:cursor-pointer block h-full w-full hover:cursor-pointer"
            type="file"
            accept="image/*"
            onChange={(e) => handleFileInputChange(e)}
          />
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <label className="block text-sm font-medium text-gray-700">
              Name
              <input
                value={name}
                onChange={onNameChange}
                disabled={isActionInProgress}
                type="text"
                autoComplete="address-level2"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
              {isClicked && showNameRequiredWarning && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  The name field is required
                </p>
              )}
            </label>
          </div>

          <div className="col-span-12">
            <label className="block text-sm font-medium text-gray-700">
              Address
              <input
                value={address}
                onChange={onAddressChange}
                disabled={isActionInProgress}
                type="text"
                autoComplete="address-level1"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
              {isClicked && showAddressRequiredWarning && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  The address field is required
                </p>
              )}
              {isClicked &&
                !showAddressRequiredWarning &&
                showInvalidAddressWarning && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Invalid address field
                  </p>
                )}
            </label>
          </div>
        </div>

        <br />

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            onClick={onButtonClick}
            disabled={isActionInProgress}
          >
            Add
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={onClose}
            disabled={isActionInProgress}
          >
            Cancel
          </button>
        </div>
      </>
    </Modal>
  );
};
